import { createRouter, createWebHistory } from 'vue-router';
import NewsFeed from '../src/components/NewsFeed.vue';
import YouTubeFeed from '../src/components/YouTubeFeed.vue';

const routes = [
  { path: '/', component: NewsFeed },
  { path: '/youtube', component: YouTubeFeed },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
